import UserWidget from "@/components/home/dashboard/widgets/UserWidgetClass";

export const WidgetViewMixin = {
  mixins: [],
  props: {
    editMode: { type: Boolean, default: false },
    widget: {type: Object},
    settings: {type: Object},
    data: {type: Object}
  },
  watch: {},
  mounted() {
  },
  data(){
    return {
      loadingWidgetResult: false,
      widgetResult: null,
    }
  },

  methods: {
    eventWidgetResized(){},

    loadWidgetResult(){
      this.loadingWidgetResult = true;
      return this.$api.homeDashboard.getWidgetResult({
        id: this.widget.id,
      })
        .then((data) => {
          this.widgetResult = data.result;
        })
        .finally(()=>{
          this.loadingWidgetResult = false;
        })
    }
  }
}