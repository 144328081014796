<template>
  <div class="dashboard-widget-template" style="height: 100%; position: relative">
    <div
      class="dashboard-widget-button-container"
      @click="handleClick"
    >
      <div
        v-if="data.icon"
        class="dashboard-widget-button-container-icon"
        :style="{
          'font-size': (data.iconSize || 14) + 'px',
          'padding': data.iconPadding + 'px',
          'color': data.iconColor || 'black',
        }"
      >
        <i :class="['fas', data.icon]"></i>
      </div>
      <div
        v-if="data.text"
        class="dashboard-widget-button-container-text"
        :style="{
          'font-size': (data.textSize || 14) + 'px',
          'padding': data.textPadding + 'px',
          'color': data.textColor || 'black',
        }"
      >
        {{data.text}}
      </div>
    </div>
  </div>
</template>

<script>
import {WidgetViewMixin} from "@/components/home/dashboard/widgets/WidgetViewMixin";

export default {
  name: 'ButtonWidgetTemplate',
  mixins: [WidgetViewMixin],
  props: {},
  methods: {
    handleClick() {
      if( this.editMode ){
        return;
      }
      if (!this.data.link) {
        return;
      }
      if( this.data.inNewWindow ){
        window.open(this.data.link, '_blank');
      } else{
        this.$router.push(this.data.link);
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard-widget-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>