var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-widget-template",staticStyle:{"height":"100%","position":"relative"}},[_c('div',{staticClass:"dashboard-widget-button-container",on:{"click":_vm.handleClick}},[(_vm.data.icon)?_c('div',{staticClass:"dashboard-widget-button-container-icon",style:({
        'font-size': (_vm.data.iconSize || 14) + 'px',
        'padding': _vm.data.iconPadding + 'px',
        'color': _vm.data.iconColor || 'black',
      })},[_c('i',{class:['fas', _vm.data.icon]})]):_vm._e(),(_vm.data.text)?_c('div',{staticClass:"dashboard-widget-button-container-text",style:({
        'font-size': (_vm.data.textSize || 14) + 'px',
        'padding': _vm.data.textPadding + 'px',
        'color': _vm.data.textColor || 'black',
      })},[_vm._v(" "+_vm._s(_vm.data.text)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }